import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import { CasumoTable } from '../components/toplist';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Casumo`}</h1>
    <p>{`Casumo är ett nytt svenskt nätcasino som blev grundat 2012 och deras enda huvudtanke är att skapa den bästa spelupplevelsen på nätet. Detta är något som man kan se på deras sida då dom har flera innovativa lösningar som är helt unikt för detta nätkasino. Under tiden man spelar så samlar man ihop olika achievement som kan vara allt från att man har fått bonusspelet tre gånger i ett spel till att man har spelat mera än 100 rundor. I skrivande stund så finns det mer än 100 olika achievement som man som spelare kan uppnå. Utöver detta så har dom ett system där man kan stiga i grader beroende på hur aktiv man är, för varje nivå man når så väntar det en ny belöning i form av free spins, bonusar och reloadbonusar.`}</p>
    <CasumoTable mdxType="CasumoTable" />
    <p>{`Det är en väldigt trevlig upplevelse att spela på Casumo, allt flyter på snabbt och man behöver inte navigera sig igenom några krångliga menyer när man ska hitta fram till sitt favorit spel. Att aktivera en bonus eller free spins belöning är väldigt enkelt, när man har en bonus tillgängligt på sitt konto så hittar man den som en pollett inne på kontot och när man klickar på den så aktivera man bonusen.`}</p>
    <p>{`Grundarna av Casumo är ett gäng som kommer ifrån Sverige och har en lång erfarenhet inom spelbranchen, dom är inget jätte företag som har glömt bort sina spelare utan jobbar för att skapa något magiskt bra inom casinovärlden.`}</p>
    <p>{`Alla betalningsmetoder på Casumo är PCI-kompatibla och uppfyller standarden för PCI. Det betyder att alla kortuppgifter förvars säkert och att alla transaktioner är skyddade på det absoult mest säkra sättet.
Hos Casumo kan du välja mellan många välkända och säkra betalmetoder – allt från gammal hederlig banköverföring och kortbetalningar med Visa och Mastercard, till e-plånböckerna Neteller och Skrill`}</p>
    <CasumoTable mdxType="CasumoTable" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      